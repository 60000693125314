import React, { FC, useEffect, useState } from 'react';
import { PaginationType, TransitionType } from 'client/widget-components';
import SliderLayoutView from './sliderView/SliderLayoutView';
import {
    PaginationPlacement,
    RuntimeSliderProps,
    sliderLayouts,
} from './sliderCommonTypes';
import { sliderlayoutTypeToDesignData } from './sliderLayouts';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { Placeholder } from 'client/widget-components/components/Placeholder';
import { SliderLayoutViewForComputedDesign } from './sliderView/SliderLayoutViewForComputedDesign';
import { useEditorContext } from 'client/infra/renderers/editorState';

import { Div } from 'client/widget-components/basicComponents';
import { eventsService } from 'client/widget-components/services/events-service/eventsService';
import { sliderLayoutsPaginationOverrides } from './sliderLayoutsPaginationOverrides';

export const widgetConfig = {
    PaginationPlacement,
};

const defaultProps = {
    selectedIndex: 0,
    autoPaginationInterval: 3000,
    transitionDuration: 1,
    transitionType: TransitionType.slideFromRight,
    shouldShowImages: true,
    shouldShowPaginationOnHover: false,
    shouldEnlargeImageOnClick: true,
};

const RuntimeSlider: FC<RuntimeSliderProps> = ({
    layout = sliderLayouts.LAYOUT_1,
    _styles,
    slidesData,
    bindingSource,
    slotsInFrame,
    paginationType: paginationTypeProp,
    ...otherProps
}) => {
    const layoutDesignData = sliderlayoutTypeToDesignData[layout];
    const paginationType =
        paginationTypeProp ??
        layoutDesignData.paginationType ??
        PaginationType.BULLETS;

    const { styles: layoutStyles } = layoutDesignData;
    const { styles: layoutPaginationStyles } =
        sliderLayoutsPaginationOverrides[paginationType]?.[layout] ?? {};
    const layoutSpecificStyles = _styles?.layoutSpecificStyles?.[layout];
    const styles = {
        container: [
            layoutStyles.container,
            layoutPaginationStyles?.container,
            _styles?.container,
            layoutSpecificStyles?.container,
        ],
        slidesContainer: [
            layoutStyles.slidesContainer,
            layoutPaginationStyles?.slidesContainer,
        ],
        slide: {
            container: [
                layoutStyles.slide_container,
                layoutPaginationStyles?.slide_container,
                _styles?.slide_container,
                layoutSpecificStyles?.slide_container,
            ],
            button: {
                root: [
                    layoutStyles.slide_button?.root,
                    layoutPaginationStyles?.slide_button?.root,
                    _styles?.slide_button?.root,
                    layoutSpecificStyles?.slide_button?.root,
                ],
                text: [
                    layoutStyles.slide_button?.text,
                    layoutPaginationStyles?.slide_button?.text,
                    _styles?.slide_button?.text,
                    layoutSpecificStyles?.slide_button?.text,
                ],
                rootHover: [
                    layoutStyles.slide_button?.rootHover,
                    layoutPaginationStyles?.slide_button?.rootHover,
                    _styles?.slide_button?.rootHover,
                    layoutSpecificStyles?.slide_button?.rootHover,
                ],
                rootHoverText: [
                    layoutStyles.slide_button?.rootHoverText,
                    layoutPaginationStyles?.slide_button?.rootHoverText,
                    _styles?.slide_button?.rootHoverText,
                    layoutSpecificStyles?.slide_button?.rootHoverText,
                ],
                buttonTypeCSSClass: _styles?.slide_button?.buttonTypeCSSClass,
            },
            title: [
                layoutStyles.slide_title,
                layoutPaginationStyles?.slide_title,
                _styles?.slide_title,
                layoutSpecificStyles?.slide_title,
            ],
            mediaContainer: [
                layoutStyles.slide_mediaContainer,
                layoutPaginationStyles?.slide_mediaContainer,
                _styles?.slide_mediaContainer,
                layoutSpecificStyles?.slide_mediaContainer,
            ],
            contentContainer: [
                layoutStyles.slide_contentContainer,
                layoutPaginationStyles?.slide_contentContainer,
                _styles?.slide_contentContainer,
                layoutSpecificStyles?.slide_contentContainer,
            ],
            desc: [
                layoutStyles.slide_desc,
                layoutPaginationStyles?.slide_desc,
                _styles?.slide_desc,
                layoutSpecificStyles?.slide_desc,
            ],
            media: [
                layoutStyles.slide_media,
                layoutPaginationStyles?.slide_media,
                _styles?.slide_media,
                layoutSpecificStyles?.slide_media,
            ],
            overlay: [
                layoutStyles.slide_overlay,
                layoutPaginationStyles?.slide_overlay,
                _styles?.slide_overlay,
                layoutSpecificStyles?.slide_overlay,
            ],
        },
        pagination: {
            container: [
                layoutStyles.pagination_container,
                layoutPaginationStyles?.pagination_container,
                _styles?.pagination_container,
                layoutSpecificStyles?.pagination_container,
            ],
            buttonArrow: [
                layoutStyles.pagination_buttonArrow,
                layoutPaginationStyles?.pagination_buttonArrow,
                _styles?.pagination_buttonArrow,
                layoutSpecificStyles?.pagination_buttonArrow,
            ],
            buttonBullet: [
                layoutStyles.pagination_buttonBullet,
                layoutPaginationStyles?.pagination_buttonBullet,
                _styles?.pagination_buttonBullet,
                layoutSpecificStyles?.pagination_buttonBullet,
            ],
            buttonBulletActive: [
                layoutStyles.pagination_buttonBulletActive,
                layoutPaginationStyles?.pagination_buttonBulletActive,
                _styles?.pagination_buttonBulletActive,
                layoutSpecificStyles?.pagination_buttonBulletActive,
            ],
            thumbsContainer: [
                layoutStyles.pagination_thumbsContainer,
                layoutPaginationStyles?.pagination_thumbsContainer,
                _styles?.pagination_thumbsContainer,
                layoutSpecificStyles?.pagination_thumbsContainer,
            ],
            buttonThumb: [
                layoutStyles.pagination_buttonThumb,
                layoutPaginationStyles?.pagination_buttonThumb,
                _styles?.pagination_buttonThumb,
                layoutSpecificStyles?.pagination_buttonThumb,
            ],
            buttonThumbActive: [
                layoutStyles.pagination_buttonThumbActive,
                layoutPaginationStyles?.pagination_buttonThumbActive,
                _styles?.pagination_buttonThumbActive,
                layoutSpecificStyles?.pagination_buttonThumbActive,
            ],
        },
    };
    const [indexFromEvent, setIndexFromEvent] = useState<number>();

    useEffect(() => {
        if (!bindingSource) {
            return;
        }
        return eventsService.listen(
            'selected-image-changed',
            bindingSource,
            (event) => setIndexFromEvent(event.detail.newIndex)
        );
    }, [bindingSource, setIndexFromEvent]);

    const sliderLayoutViewPropsBase = {
        ...otherProps,
        ...layoutDesignData,
        styles,
        selectedIndex: indexFromEvent ?? otherProps.selectedIndex,
        paginationType,
    };
    const slotsInFrameProp =
        layoutDesignData.strictSlotsInFrame ??
        slotsInFrame ??
        layoutDesignData.defaultSlotsInFrame ??
        1;
    const { isInEditor } = useEditorContext();

    return (
        <Div
            style={{ height: '100%', overflow: 'hidden' }}
            data-auto='slider-wrapper'
        >
            {slidesData.length === 0 ? (
                <Placeholder />
            ) : (
                <SliderLayoutView
                    {...sliderLayoutViewPropsBase}
                    layout={layout}
                    paginationShow={otherProps.paginationShow}
                    slidesData={slidesData}
                    autoPagination={
                        otherProps.autoPagination && {
                            ...otherProps.autoPagination,
                            on:
                                otherProps.overrideAutoPaginationOn ??
                                otherProps.autoPagination?.on,
                        }
                    }
                    dataAuto='actual-slider'
                    slotsInFrame={slotsInFrameProp}
                />
            )}
            {isInEditor && (
                <SliderLayoutViewForComputedDesign
                    {...sliderLayoutViewPropsBase}
                />
            )}
        </Div>
    );
};

RuntimeSlider.defaultProps = defaultProps;

const BoundedSlider = withErrorBoundary({
    Comp: RuntimeSlider,
    componentName: 'RuntimeSlider',
    logProps: true,
});

export default BoundedSlider;
